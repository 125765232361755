<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>saving-money-flower</title>
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="11.946"
      y1="12.75"
      x2="11.946"
      y2="23.25"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M11.946,23.25s-1.13-3.091-6.308-6.386"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M3.661,19c1.026,1.116,2.642,1.523,3.623-.564C9.711,17,8.716,15.182,7.337,14.23a7.219,7.219,0,0,0-6.523.1C2.47,15.935,2.385,17.609,3.661,19Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M11.946,23.25s1.13-3.091,6.309-6.386"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M20.231,19c-1.025,1.116-2.642,1.523-3.622-.564-2.427-1.438-1.432-3.255-.054-4.207a7.222,7.222,0,0,1,6.524.1C21.422,15.935,21.508,17.609,20.231,19Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M13.446,3.75H11.413a1.341,1.341,0,0,0-.5,2.587l2.063.826a1.342,1.342,0,0,1-.5,2.587H10.446"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="11.946"
      y1="3.75"
      x2="11.946"
      y2="3"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="11.946"
      y1="10.5"
      x2="11.946"
      y2="9.75"
    />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="11.946" cy="6.75" r="6" />
  </svg>
</template>
